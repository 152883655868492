<script setup>
//import { url } from 'inspector';

const mirawayEcosystem = [
  {
    id: 1,
    title: "Công ty Cổ phần Miraway Giải pháp công nghệ",
    year: "2007",
    logo: "/images/logos/miraway.svg",
    url: "https://miraway.vn/",
  },
  {
    id: 2,
    title: "Công ty TNHH Datanomics",
    year: "2015",
    logo: "/images/logos/gobysend.svg",
    url: "http://datanomics.com.vn/",
  },
  {
    id: 3,
    title: "Công ty Cổ phần Công nghệ Mirasoft",
    year: "2016",
    logo: "/images/logos/mirasoft.svg",
    url: "https://qrx.com.vn/",
  },
  {
    id: 4,
    title: "Công ty Cổ phần Gobysend",
    year: "2019",
    logo: "/images/logos/datanomics.svg",
    url: "https://goby.vn/",
  },
  {
    id: 5,
    title: "Công ty Cổ phần Chuyển đổi số Miraway",
    year: "2023",
    logo: "/images/logos/miraway-but-white.svg",
    url: "https://chuyendoiso.miraway.vn/",
  },
];
</script>
<template>
  <section id="miraway-ecosystem" class="2xl:container mx-auto">
    <div class="w-full top-10 justify-center pb-[58px]">
      <div class="max-md:hidden max-xl:px-20">
        <div class="timeline">
          <div class="timeline-content" v-for="time in mirawayEcosystem" :key="time.id">
            <nuxt-link :to="time.url" class="timeline-link" target="_blank">
              <div class="timeline-body">
                <div class="timieline-title">{{ time.title }}</div>
                <div class="timeline-year">{{ time.year }}</div>
                <nuxt-picture
                  loading="lazy"
                  :src="time.logo"
                  :imgAttrs="{ class: 'w-[126px] h-[126px] max-xl:w-[90px]', alt: 'miraway-ecosystem' + time.id }"
                  class="timeline-logo"
                />
              </div>
            </nuxt-link>
            <div class="time-line-pointer"></div>
          </div>
        </div>
      </div>
      <div class="hidden max-md:grid grid-cols-1 gap-4 mx-4">
        <div v-for="item in mirawayEcosystem" :key="item.id" class="ecosystem-item">
          <nuxt-link :to="item.url" target="_blank">
            <p class="!font-bold heading-4 text-primary max-w-[270px]">{{ item.title }}</p>
            <p class="mt-3 text-[16px] text-[#353945]">{{ item.year }}</p>
            <nuxt-picture
              loading="lazy"
              :src="item.logo"
              :imgAttrs="{ class: 'w-[126px] h-[126px]', alt: 'miraway-ecosystem' + item.id }"
            />
          </nuxt-link>
        </div>
      </div>
    </div>
  </section>
</template>
